export const getUniqueCarrierServiceOptions = (options) => {
    const markers = new Set();
    const unique = [];

    options.forEach(service => {
        if (markers.has(service.name)) {
            return;
        }

        markers.add(service.name);
        unique.push(service);
    });

    return unique;
};