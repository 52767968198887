<template>
    <div>
        <page-header v-if="isAdmin">
            <template slot="tools">
                <div class="flex">
                    <router-link
                        :to="{ name: 'admin.sites'}"
                        class="inline-block mr-4 btn-transparent"
                    >Cancel</router-link>
                    <button
                        :disabled="!allowSave"
                        @click="saveSite"
                        :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']"
                    >Save</button>
                </div>
            </template>
        </page-header>

        <card class="border-radius" :class="{ 'mt-6': isAdmin }" bodyClass="p-0 -mx-6 overflow-visible" title="General information" title-no-border>
            <div class="flex flex-wrap w-full px-6 pb-8">
                <modal-field label="Name" class="w-1/3 pr-8 mt-4">
                    <input
                        type="text"
                        v-model="site.name"
                        :class="{'border-red-500' : validationErrors.name}"
                    />

                    <transition name="fade">
                        <span v-if="validationErrors.name" class="w-full text-red-500 help-block">
                            <span>{{validationErrors.name[0]}}</span>
                        </span>
                    </transition>
                </modal-field>

                <div
                    v-if="this.$route.name !== 'settings.sites.edit'"
                    class="flex flex-col w-1/3 pr-8 mt-4"
                >
                    <modal-field label="Organization" class="relative">
                        <multiselect
                            v-model="selectedOrganization"
                            :options="organizations"
                            :multiple="false"
                            :class="{'border-red-500' : validationErrors.organization_id}"
                            placeholder="Please select"
                            track-by="id"
                            @input="parseSelectOrganization"
                            label="name"
                        >
                            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>

                        <transition name="fade">
                            <span
                                v-if="validationErrors.organization_id"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.organization_id[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                </div>
                <modal-field label="Identifier" class="w-1/3 pr-8 mt-4">
                    <input
                        type="text"
                        v-model="site.key"
                        :class="{'border-red-500' : validationErrors.key}"
                    />

                    <transition name="fade">
                        <span v-if="validationErrors.key" class="w-full text-red-500 help-block">
                            <span>{{validationErrors.key[0]}}</span>
                        </span>
                    </transition>
                </modal-field>
                <modal-field label="VAT-number" class="w-1/3 pr-8 mt-4">
                    <the-mask
                        :mask="'AA-XXXXXXXXXXXX'"
                        type="text"
                        placeholder="AB-12345679"
                        v-model="site.vat_number"
                        :class="{'border-red-500' : validationErrors.vat_number}"
                    />
                    <transition name="fade">
                        <span
                            v-if="validationErrors.vat_number"
                            class="w-full text-red-500 help-block"
                        >
                            <span>{{validationErrors.vat_number[0]}}</span>
                        </span>
                    </transition>
                </modal-field>
                <modal-field label="Generel e-mail address" class="w-1/3 pr-8 mt-4">
                    <input
                        type="text"
                        v-model="site.email"
                        :class="{'border-red-500' : validationErrors.email}"
                    />
                    <transition name="fade">
                        <span v-if="validationErrors.email" class="w-full text-red-500 help-block">
                            <span>{{validationErrors.email[0]}}</span>
                        </span>
                    </transition>
                </modal-field>
                <modal-field label="Phone number" class="w-1/3 pr-8 mt-4">
                    <the-mask
                        :mask="'+#############'"
                        type="text"
                        v-model="site.phone"
                        :class="{'border-red-500' : validationErrors.phone}"
                    />
                    <transition name="fade">
                        <span v-if="validationErrors.phone" class="w-full text-red-500 help-block">
                            <span>{{validationErrors.phone[0]}}</span>
                        </span>
                    </transition>
                </modal-field>
                <div class="flex w-full">
                    <modal-field label="Address" class="w-1/3 pr-8 mt-4">
                        <input
                            type="text"
                            v-model="site.address"
                            :class="{'border-red-500' : validationErrors.address}"
                        />
                        <transition name="fade">
                            <span
                                v-if="validationErrors.address"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.address[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                    <modal-field label="ZIP code" class="w-1/3 pr-8 mt-4">
                        <the-mask
                            :mask="'##########'"
                            type="text"
                            v-model="site.post_code"
                            :class="{'border-red-500' : validationErrors.post_code}"
                        />
                        <transition name="fade">
                            <span
                                v-if="validationErrors.post_code"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.post_code[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                    <modal-field label="City" class="w-1/3 pr-8 mt-4">
                        <input
                            type="text"
                            v-model="site.city"
                            :class="{'border-red-500' : validationErrors.city}"
                        />
                        <transition name="fade">
                            <span
                                v-if="validationErrors.city"
                                class="w-full text-red-500 help-block"
                            >
                                <span>{{validationErrors.city[0]}}</span>
                            </span>
                        </transition>
                    </modal-field>
                </div>
                <div v-if="isAdmin" class="flex w-1/3 pr-8 mt-4">
                    <modal-field label="Is active">
                        <checkbox v-model="site.is_active" class="mt-2"></checkbox>
                    </modal-field>
                </div>
                <div v-if="isSuperAdmin" class="flex w-1/3 pr-8 mt-4">
                    <modal-field v-if="hasCarrierServiceOptions" label="Excluded carriers services" class="w-full">
                        <multi-select
                            
                            select-label="name"
                            select-value="id"
                            :allow-clear="false"
                            :allow-empty="true"
                            :branding="false"
                            :multiple="true"
                            :options="carrierServiceOptions"
                            v-model="site.excluded_carrier_options"
                        >
                        </multi-select>  
                    </modal-field>
                </div>
            </div>
        </card>

        <card v-if="edit" class="border-radius overflow-hidden mt-6" bodyClass="p-0 -mx-6 min-h-80" title="Users" title-no-border>
            <template slot="tools">
                <button v-if="allowAddUsers" @click="toggleAddNewUser" href="#">+ Add new user</button>
            </template>
            <div class="flex flex-wrap w-full">
                <v-table
                    id="users"
                    :columns="columns"
                    :tools="false"
                    row-key="uuid"
                    :query-params="true"
                    ref="users"
                    :endpoint="endpoint"
                    sort-key="created_at"
                    :filter-params="filterParams"
                    no-cache
                    @row-click="rowClicked"
                >
                    <template slot="cell" slot-scope="{row, col}">
                        <span
                            class="dotted-underline"
                            v-if="col.key === 'users'"
                        >{{ row[col.key].length }} users</span>

                        <div
                            v-else-if="col.key === 'organization'"
                            :title="row[col.key].name"
                        >{{ row[col.key].name }}</div>

                        <div
                            v-else-if="col.key === 'site'"
                            :title="row[col.key].name"
                        >{{ row[col.key].name }}</div>
                        

                        <span class="block w-full text-right" v-else-if="col.key === 'remove'">
                            <button class="mr-3" @click="editUser(row.id)">
                                <svg-importer icon-name="icons/edit" key="site-edit" />
                            </button>
                            <button @click="removeUser(row.id)">
                                <svg-importer icon-name="icons/close" key="site-close" width="20" />
                            </button>
                        </span>
                        <span v-else>{{ row[col.key] }}</span>
                    </template>

                    <template slot="empty">
                        <div class="flex items-center justify-center w-full min-h-64">
                            <div>
                                <div class="flex justify-center w-full">
                                    <fa :icon="['fal','user']" class="block text-gray-500 text-7xl" />
                                </div>
                                <p
                                    class="w-full mt-2 text-center text-gray-500"
                                >No users have been added yet</p>
                            </div>
                        </div>
                    </template>
                </v-table>
            </div>

            <add-user-modal
                :show="showAddUser"
                @hide="showAddUser = false"
                @success="fetchUsers()"
                @loading="changeLoadStatus"
                :organization="this.selectedOrganization"
            ></add-user-modal>

            <delete-modal
                :show="showDeleteModal"
                @hide="closeDeleteModal"
                name="Site User"
                :selectedObject="selectedUser"
                :organization="selectedOrganization"
                :organizations="organizations"
                :sites="sites"
                @delete="initDelete"
            ></delete-modal>

            <modal :show="loading" :loader="true"></modal>
        </card>

        <card v-if="edit" class="border-radius overflow-hidden mt-6" title="Applications" title-no-border>
            <router-link :to="{name: 'settings.site.applications', params: {id: $route.params.id }}" class="btn-primary"
            >Edit applications</router-link>
        </card>
    </div>
</template>

<script>
import axios from 'axios';
import MultiSelect from '~/components/MultiSelect';
import { getUniqueCarrierServiceOptions } from '~/utils/carrier-service-options.js';

export default {
    name: 'AddSite',
    components: {
        MultiSelect,
    },
    metaInfo() {
        return { title: 'Add site' };
    },
    data () {
        return {
            site: {
                name: null,
                organization_id: null,
                vat_number: null,
                email: null,
                key: null,
                phone: null,
                address: null,
                post_code: null,
                city: null,
                description: null,
            },
            loading: false,
            showDeleteModal: false,
            selectedUser: {},
            validationErrors: {},
            validationUserErrors: {},
            showAddUser: false,
            selectedOrganization: null,
            carrierServiceOptions: [],
            carrierOptions: [],
            organizations: [],
            sites: [],
            carriers: [],
            users: [],
            siteBeforeChange: {},
            filterParams: {},
        };
    },
    computed: {
        columns() {
            const userColumns = [
                {
                    title: "Email",
                    key: "email",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2",
                },
                {
                    title: "Name",
                    key: "name",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2",
                },
            ];

            if (this.isAdmin) {
                userColumns.push({
                    title: "",
                    key: "remove",
                    toggled: true,
                    sortable: false,
                    width: "w-1/5",
                });
            }

            return userColumns
        },
        allowRemoveUsers() {
            return this.$store.getters['auth/roles']?.find(role => ['GOD USERS', 'Organization Admin'].includes(role.name));
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        isSuperAdmin() {
            return this.$store.getters['auth/isGodUser'];
        },
        allowAddUsers() {
            return this.$store.getters['auth/isAdmin'];
        },
        edit() {
            return this.$route.name !== 'admin.sites.create';
        },
        endpoint() {
            return `${this.$apiUrl.sites.base}/` + this.$route.params.id + '/users';
        },
        routeIncludesAdmin() {
            return this.$route.path.includes('admin');
        },
        allowSave() {
            return JSON.stringify(this.siteBeforeChange) !== JSON.stringify(this.site);
        },
        hasCarrierServiceOptions() {
            return Array.isArray(this.carrierServiceOptions) && this.carrierServiceOptions.length > 0;
        },
        hasCarrierOptions() {
            return Array.isArray(this.carrierOptions) && this.carrierOptions.length > 0;
        },
    },
    created() {
        Promise.all([
            this.initCarriserData(),
            this.fetchOrganizations()
        ]);

        if (this.edit) {
            this.fetchSite(this.$route.params.id);
            this.fetchUsers();
        }
    },
    methods: {
        initCarriserData() {
            this.fetchCarriers().then(this.fetchCarrierOptions).then(this.fetchCarrierServiceOptions);
        },
        carrierById(id) {
            return this.carriers.find((carrier) => {
                return carrier.id === id
            })
        },
        async fetchCarriers() {
            try {
                const { data } = await axios.get(this.$apiUrl.carriers.base + '?paginate=0')
                this.carriers = data.data;
            } catch (error) {
                this.carriers = [];
            }
        },
        async fetchCarrierOptions() {
            try {
                const {data: { data }} = await axios.get(`${this.$apiUrl.carriers.base}?limit=10000`);
                this.carrierOptions = data;
            } catch (error) {
                this.carrierOptions = [];
            }
        },
        async fetchCarrierServiceOptions() {
            try {
                const { data: { data } } = await axios.get(`${this.$apiUrl.carrierServices.base}?limit=10000`)
    
                this.carrierServiceOptions = getUniqueCarrierServiceOptions(data);
            } catch (error) {
                this.carrierServiceOptions = [];
            }
        },

        changeLoadStatus(newStatus) {
            this.loading = newStatus;
        },
        editUser(id) {
            this.$router.push({
                name: 'settings.user.edit',
                params: {
                    id: id,
                },
            });
        },
        toggleAddNewUser() {
            this.showAddUser = !this.showAddUser;
        },
        parseSelectOrganization() {
            if (!this.organizations.length) {
                setTimeout(() => {
                    this.parseSelectOrganization();
                }, 100);
                
                return;
            }

            if (this.selectedOrganization != null) {
                this.site.organization_id = this.selectedOrganization.id;

                return;
            }

            const organization = this.organizations.find(
                (organization) => organization.id == this.site.organization_id
            );
            this.selectedOrganization = organization;
        },
        parseSelectUserLevel() {
            this.modal.level = this.modal.selectedUserLevel.key;
        },
        setBeforeChangeState(site) {
            this.siteBeforeChange = JSON.parse(JSON.stringify(site));
        },

        async fetchOrganizations() {
            const { data } = await axios.get(
                this.$apiUrl.organizations.base + '?pageSize=1000'
            );

            this.organizations = data.data;
        },
        async saveSite() {
            this.loading = true;

            let url = this.$apiUrl.sites.base;
            let action = "post";

            if (this.$route.params.id && this.edit) {
                let id = this.$route.params.id;
                url = `${this.$apiUrl.sites.base}/` + id;
                action = "put";
            }

            try {
                const { data } = await axios[action](url, { ...this.site, is_active: Number(this.site.is_active) });
                let id = data.data.id;

                this.validationErrors = [];

                if (!this.edit) {
                    await this.$router.push({
                        name: 'admin.sites.edit',
                        params: { id: id },
                    });
                }

                this.$snotify.success(`Site saved!`);
                this.setBeforeChangeState(this.site);

                this.$store.commit('auth/SET_SITES_TRIGGER');

                this.loading = false;
            } catch (error) {
                this.loading = false;
                if (error.response.status === 422) {
                    this.validationErrors = error.response.data.errors;
                }
            }
        },
        async fetchSite(id) {
            try {
                let params = {
                    include: 'users',
                };
                const { data } = await axios.get(`${this.$apiUrl.sites.base}/` + id, params);

                this.site = data.data;
                this.setBeforeChangeState(this.site);
                if (this.site) {
                    this.parseSelectOrganization();
                }
            } catch (e) {
                console.log(e, "error");
            }
        },

        async fetchUsers() {
            const { data } = await axios.get(this.$apiUrl.users.list + '?pageSize=1000');
            this.users = data.data;
            await this.$refs.users.table.fetchRows();
        },
        closeDeleteModal() {
            if (this.$refs?.sites?.table) {
                this.$refs.sites.table.fetchRows();
            }

            this.showDeleteModal = false;
            this.selectedUser = null;
        },
        removeUser(id) {
            this.showDeleteModal = true;
            this.selectedUser = this.users.find((user) => user.id == id);
        },
        async deleteUser() {
            await axios.delete(`${this.$apiUrl.sites.base}/${this.$route.params.id}/user/${this.selectedUser.id}`);
            this.showDelete = false;
            await this.$refs?.users?.table?.fetchRows();
            this.closeDeleteModal();
        },
        clearUserForm() {
            this.modal.forEach((element) => {
                element.value = null;
            });
        },
        initDelete() {
            this.deleteUser();
        },
        rowClicked() {},
        excludedCarrierServicesString(services) {
            if (!services) {
                return;
            }
            
            return services.map(service => service.name).join(', ');
        }
    },
};
</script>

<style lang="scss" >
    .border-red-500 {
        border-color: #f56565 !important;

        .multiselect__tags {
            border-color: #f56565 !important;
        }
    }
</style>
